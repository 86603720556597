import { EngineeringNotebookStepField, FeelingType } from '@prisma/client';
import dayjs from 'dayjs';

import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import { FiTarget } from 'react-icons/fi';
import { GoSearch } from 'react-icons/go';
import { HiOutlineCloud, HiOutlineFaceSmile, HiUsers } from 'react-icons/hi2';
import { IoContract } from 'react-icons/io5';
import { AssignmentSetLogData } from 'types/models/Assignment';

dayjs.extend(relativeTime);
interface Student {
  id: string;
  firstName: string;
  lastName: string;
}

export const setNotebookStepIcon = (field?: EngineeringNotebookStepField) => {
  switch (field) {
    case 'OBJECTIVES':
      return (
        <FiTarget
          size={22}
          className="p-0.5 text-gray-700 border-gray-700 border rounded-xl"
        />
      );
    case 'CONSTRAINTS':
      return (
        <IoContract
          size={24}
          className="text-blue-700 border-blue-700 border rounded-xl p-0.5"
        />
      );
    case 'IDEA':
    case 'FEELING':
    case 'CONFUSED':
    case 'QUESTION':
    case 'DISCOVERED':
    case 'CONNECTION':
      return (
        <HiOutlineCloud
          size={24}
          className="text-red-700 border-red-700 border rounded-xl p-0.5"
        />
      );
    default:
      return (
        <GoSearch
          size={24}
          className="text-orange-700 border-orange-700 border rounded-xl p-0.5"
        />
      );
  }
};

const Wrapper: React.FC<{
  student: Student;
  icon: React.ReactNode;
  children: React.ReactNode;
}> = ({ student, icon, children }) => {
  return (
    <>
      <div className="flex text-sm font-semibold justify-between relative">
        <span className="pl-2 text-gray-700">{student?.firstName}</span>
        <span className="text-xs m-1 absolute top-3/4 right-2 transform -translate-y-1/2 rounded-full bg-white">
          <span>{icon}</span>
        </span>
      </div>
      {children}
    </>
  );
};

export const EngineeringNotebookStep: React.FC<{
  step: AssignmentSetLogData['engineeringNotebookSteps'][0];
}> = React.memo(({ step }) => {
  if (!step.field) {
    return null;
  }
  return (
    <Wrapper student={step.student} icon={setNotebookStepIcon(step.field)}>
      <div
        className={`flex justify-between items-center px-2 py-3 bg-white border-1 shadow-md rounded-lg line-clamp-2`}
      >
        {step.data}
      </div>

      <div className="ml-2 text-xs text-gray-400">
        {dayjs(step.createdAt).fromNow()}
      </div>
    </Wrapper>
  );
});

export const getFeelingColor = (type: keyof typeof FeelingType): string => {
  switch (type) {
    case 'HAPPY':
    case 'CALM':
    case 'FOCUSED':
    case 'READYTOLEARN':
      return 'bg-green-500';

    case 'SAD':
    case 'SICK':
    case 'WITHDRAWN':
    case 'TIRED':
      return 'bg-blue-300';

    case 'FRUSTRATED':
    case 'WORRIED':
    case 'SILLY':
    case 'EXCITED':
      return 'bg-yellow-500';

    case 'MAD':
    case 'TERRIFIED':
    case 'YELLING':
    case 'FURIOUS':
      return 'bg-red-500';

    default:
      return 'bg-gray-400';
  }
};

export const FeelingIcon: React.FC<{ type: FeelingType }> = () => {
  return (
    <HiOutlineFaceSmile
      size={22}
      className={`rounded-xl text-green-800 border border-green-800 p-0.5`}
    />
  );
};

export const FeelingsTracker: React.FC<{
  feeling: AssignmentSetLogData['feelingsTracker'][0];
}> = React.memo(({ feeling }) => {
  return (
    <Wrapper
      student={feeling.student}
      icon={<FeelingIcon type={feeling.type} />}
    >
      <div className="flex justify-between items-center px-2 py-3 bg-white border-1 shadow-md rounded-lg">
        <div className="flex items-center ml-2 align-middle">
          <div className="text-sm text-gray-500 font-bold capitalize">
            {feeling.type.toLowerCase()}
          </div>

          <span
            className={`w-3.5 h-3.5 rounded-full ml-1 ${getFeelingColor(
              feeling.type
            )}`}
          />
        </div>
      </div>
      <div className="ml-2 text-xs text-gray-400">
        {dayjs(feeling.createdAt).fromNow()}
      </div>
    </Wrapper>
  );
});

export const KudosIcon: React.FC<{ isPositive: boolean }> = () => (
  <HiUsers
    size={22}
    className={`rounded-xl p-0.5 text-purple-500 border border-purple-500`}
  />
);

export const Kudos: React.FC<{
  kudo: AssignmentSetLogData['kudos'][0];
}> = React.memo(({ kudo }) => (
  <Wrapper
    student={kudo.sender}
    icon={<KudosIcon isPositive={kudo.isPositive} />}
  >
    <div className="p-3 bg-white border-1 rounded-xl shadow-sm text-sm text-gray-500">
      <span className="text-gray-600 font-bold">
        {kudo.receiver.firstName}{' '}
      </span>
      Received{' '}
      <span
        className={`font-bold ${
          kudo.isPositive ? 'text-green-700' : 'text-red-700'
        }`}
      >
        {kudo.isPositive ? 'positive' : 'negative'}
      </span>{' '}
      kudo for{' '}
      <span className="capitalize">{kudo.type.toLocaleLowerCase()}</span>
    </div>
    <div className="ml-2 text-xs text-gray-400">
      {dayjs(kudo.createdAt).fromNow()}
    </div>
  </Wrapper>
));
