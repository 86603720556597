import { useQuery } from '@tanstack/react-query';
import { Routes } from 'lib/constants';
import axiosInstance from 'lib/react-query/axios';
import { filterEmptyKeys } from 'lib/react-query/queryKeys';

const getTasksAvailableUnits = async () => {
  const { data } = await axiosInstance.get<{ value: string; label: string }[]>(
    `${Routes.API.TASKS}/units`
  );
  return data;
};

export const useTasksAvailableUnits = ({
  teacherId,
  queryKey,
}: {
  teacherId: string;
  queryKey: (string | number)[];
}) => {
  const query = useQuery({
    // key must match getServerSideProps or hydration error
    queryKey: filterEmptyKeys(queryKey),
    queryFn: () => getTasksAvailableUnits(),
    enabled: !!teacherId,
    staleTime: 1000 * 60 * 60 * 1, // 1 hours
  });

  return query;
};
