const QueryKeys = {
  I18N: 'i18n',
  USER: 'user',
  ME: 'me',
  USERS: 'users',
  CLASSROOMS_HOME: 'classrooms-home',
  CLASSROOM: 'classroom',
  CLASSROOMS: 'classrooms',
  CLASSROOM_GROUPSETS: 'classroom-groupsets',
  GROUP: 'group',
  ASSIGNMENTS: 'assignments',
  ASSIGNMENT_DETAILS: 'assignment-details',
  ASSIGNMENT_LOG: 'assignment-log',
  ASSIGNMENTS_HOME: 'assignments-home',
  TASKS_HOME: 'tasks-home',
  TASKS: 'tasks',
  TASK: 'task',
  ACTIVE_ASSIGNMENTS: 'active-assignments',
  ALL_CLASSROOMS_ASSIGNMENTS: 'all-classrooms-assignments',
  INACTIVE_ASSIGNMENTS: 'inactive-assignments',
  CLASSROOM_MEMBERS: 'classroom-members',
  CLASSSROOM_STUDENT_MANAGEMENT: 'classroom-student-management',
  ASSIGNMENTS_BY_STUDENT: 'assignments-by-student',
  ASSIGNMENT_FILTER_OPTIONS: 'assignment-filter-options',
  GROUP_REPORT: 'group-report',
  ASSIGNMENT_REPORT: 'assignment-report',
  CHALLENGES_ASSIGNMENT_REPORT: 'challenges-assignment-report',
  CHALLENGES_ASSIGNMENT_REFLECTIONS_REPORT:
    'challenges-assignment-reflections-report',
  CHALLENGES_ASSIGNMENT_TESTING_RESULTS_REPORT:
    'challenges-assignment-testing-results-report',
  CHALLENGES_ASSIGNMENT_CONSTRAINTS_REPORT:
    'challenges-assignment-constraints-report',

  CHALLENGES_ASSIGNMENT_CRITERIAS_REPORT:
    'challenges-assignment-criterias-report',
  CHALLENGES_ASSIGNMENT_SUPER_SET_DATA: 'challenges-assignment-super-set-data',
  CREATE_SPACE_REPORT: 'create-space-report',
  CREATE_SPACE_ASSIGNMENT_REPORT: 'create-space-assignment-report',
  CREATE_SPACE_ASSIGNMENT_TESTING_RESULTS_REPORT:
    'create-space-assignment-testing-results-report',
  CREATE_SPACE_ASSIGNMENT_REFLECTIONS_REPORT:
    'create-space-assignment-reflections-report',
  CREATE_SPACE_ASSIGNMENT_CONSTRAINTS_REPORT:
    'create-space-assignment-constraints-report',
  CREATE_SPACE_ASSIGNMENT_CRITERIAS_REPORT:
    'create-space-assignment-criterias-report',

  DASHBOARD_STATUS: 'dashboard-status',
  TEXT_LABELS: 'text-labels',
  TEXT_LABELS_HOME: 'text-labels-home',
  CLASSROOMS_ANALYTICS: 'classrooms-analytics',
  STUDENT_ANALYTICS: 'student-analytics',
  CLASSROOM_AND_STUDENTS: 'classroom-and-students',
  CLEVER_CLASSROOMS_SYNC: 'clever-classrooms-sync',
  USER_SUBSCRIPTION: 'user-subscription',
  VIEW_ALL_PRODUCT_KEYS: 'view-all-product-keys',
  ENROLL_IN_SUBSCRIPTION: 'enroll-in-subscription',
  TASKS_UNITS: 'tasks-units',
} as const;

export type QueryKeysType = (typeof QueryKeys)[keyof typeof QueryKeys];

export const filterEmptyKeys = (
  queryKey: Array<string | number | undefined | null>
) => {
  return queryKey.filter((item) => item || item === 0) as Array<
    string | number
  >;
};

export default QueryKeys;
