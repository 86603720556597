import { MetaProvider } from '@/components/Meta';
import { CreateSpaceReportManagerProvider } from '@/lib/Hooks/useCreateSpaceReportManager';
import { WebViewProvider, useWebView } from '@/lib/Hooks/useIsWebViewContext';
import { LikesAndMessagesReportManagerProvider } from '@/lib/Hooks/useLikesAndMessagesReportManager';
import { TaskModalProvider } from '@/lib/Hooks/useTasksContext';
import getQueryClientConfig from '@/lib/react-query/queryClientConfig';
import { TooltipProvider } from '@radix-ui/react-tooltip';
import { TourProvider } from '@reactour/tour';
import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { dir } from 'i18next';

import { CSPostHogProvider } from '@/lib/Hooks/PostHogProvider';
import { ChallengeSetReportManagerProvider } from '@/lib/Hooks/useChallengeSetReportManager';
import { NotebookTemplateSetProvider } from '@/lib/Hooks/useNotebookTemplateContext';
import {
  OnBoardingProvider,
  onBoardingDesktopSteps,
} from '@/lib/Hooks/useOnBoardingContext';
import { Routes, WEBAPP_URL } from '@/lib/constants';
import axiosInstance from '@/lib/react-query/axios';
import { Analytics } from '@vercel/analytics/react';
import i18n from 'i18next';
import { Session } from 'next-auth';
import { SessionProvider, useSession } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';
import type { AppProps as NextAppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { initReactI18next } from 'react-i18next';
import { ClientUser, UserUpdateHasSeenOnboarding } from 'types/models/User';
import Button from '../Button';

export type AppProps = Omit<
  NextAppProps<Record<string, unknown>>,
  'Component'
> & {
  Component: NextAppProps['Component'] & {
    PageWrapper?: (props: AppProps) => JSX.Element;
  };
  session: Session;
  /** Will be defined only is there was an error */
  err?: Error;
};
export interface MindlabsPageWrapper {
  (props?: any): JSX.Element;
  PageWrapper?: AppProps['Component']['PageWrapper'];
}
const ProgressBar = dynamic(() => import('@/components/Loading/ProgressBar'), {
  ssr: false,
});
const PageWrapper = ({ Component, pageProps, session, ...rest }: AppProps) => {
  const [queryClient] = useState(() => new QueryClient(getQueryClientConfig()));
  const [isStaging, setIsStaging] = useState(false);
  const [isDev, setIsDev] = useState(false);

  useEffect(() => {
    if (window.location.hostname.includes('staging')) {
      setIsStaging(true);
    }
    if (window.location.hostname.includes('dev')) {
      setIsDev(true);
    }
  });

  i18n.use(initReactI18next);

  const I18nextAdapter = appWithTranslation<
    NextAppProps & {
      children: React.ReactNode;
    }
  >(({ children }) => <>{children}</>);

  type AppPropsWithChildren = NextAppProps & {
    children: ReactNode;
  };

  const CustomI18nextProvider = (props: AppPropsWithChildren) => {
    /**
     * i18n should never be clubbed with other queries, so that it's caching can be managed independently.
     **/

    const session = useSession();
    const locale = session?.data?.user.locale ?? 'en';
    const [i18nData, setI18nData] = useState<any>(null); // Replace 'any' with the correct type

    useEffect(() => {
      try {
        // @ts-expect-error TS2790: The operand of a 'delete' operator must be optional.
        delete window.document.documentElement['lang'];

        window.document.documentElement.lang = locale;
        // Next.js writes the locale to the same attribute
        // https://github.com/vercel/next.js/blob/1609da2d9552fed48ab45969bdc5631230c6d356/packages/next/src/shared/lib/router/router.ts#L1786
        // which can result in a race condition
        // this property descriptor ensures this never happens
        Object.defineProperty(window.document.documentElement, 'lang', {
          configurable: true,
          // value: locale,
          set: function (this) {
            // empty setter on purpose
          },
          get: function () {
            return locale;
          },
        });

        const fetchI18nData = async () => {
          try {
            const data = await getI18n(locale);
            setI18nData(data);
          } catch (error) {
            console.error('Error fetching i18n data:', error);
          }
        };
        fetchI18nData();
      } catch (error) {
        console.error(error);

        window.document.documentElement.lang = locale;
      }

      window.document.dir = dir(locale);
    }, [locale]);

    const i18n = i18nData?.i18n;
    const passedProps = {
      ...props,
      pageProps: {
        ...i18n,
      },
    };

    return <I18nextAdapter {...passedProps} Component={props.Component} />;
  };
  const getI18n = async (locale: string) => {
    const url = `${WEBAPP_URL}${Routes.API.I18N}`;
    const { data } = await axiosInstance.get(url, {
      params: { locale },
    });
    return data;
  };

  const { nonce, ...restPageProps } = pageProps;
  const propsWithoutNonce = {
    Component,
    pageProps: {
      ...restPageProps,
    },
    ...rest,
  };

  return (
    <TourProvider
      position={'right'}
      steps={onBoardingDesktopSteps}
      onClickClose={({ setIsOpen, steps, currentStep }) => {
        if (!steps) return null;
        const stepsLength = steps?.length;
        const last = currentStep === stepsLength - 1;
        if (last) {
          hasSeenOnBoarding({ hasSeenOnBoarding: true });
          setIsOpen(false);
        }
      }}
      padding={1}
      prevButton={({ currentStep, setCurrentStep, steps }) => {
        const first = currentStep === 0;
        if (!steps || !first) return null;

        return (
          <Button
            color="minimal"
            className=" underline"
            onClick={() => {
              setCurrentStep(() => steps?.length - 1);
            }}
          >
            {'Skip Tour'}
          </Button>
        );
      }}
      showDots={false}
      nextButton={({
        currentStep,
        stepsLength,
        setIsOpen,
        setCurrentStep,
        steps,
      }) => {
        const last = currentStep === stepsLength - 1;
        const first = currentStep === 0;
        if (!steps) return null;

        return (
          <Button
            size={'sm'}
            className={first ? '' : `absolute right-5 bottom-3`}
            onClick={() => {
              if (last) {
                hasSeenOnBoarding({ hasSeenOnBoarding: true });
                setIsOpen(false);
              } else {
                setCurrentStep((s) => (s === steps?.length - 1 ? 0 : s + 1));
              }
            }}
          >
            {last ? 'Finish' : 'Next'}
          </Button>
        );
      }}
      showBadge={false}
      badgeContent={({ totalSteps, currentStep }) =>
        currentStep + 1 + '/' + totalSteps
      }
    >
      <WebViewProvider>
        <WebViewSetup />
        <SessionProvider session={session}>
          <QueryClientProvider client={queryClient}>
            <CSPostHogProvider>
              <CustomI18nextProvider {...propsWithoutNonce}>
                <ReactQueryDevtools
                  initialIsOpen={false}
                  buttonPosition="top-right"
                />
                <HydrationBoundary state={pageProps.dehydratedState}>
                  <Toaster />
                  <ProgressBar />
                  <TooltipProvider>
                    <MetaProvider>
                      <TaskModalProvider>
                        <NotebookTemplateSetProvider>
                          <OnBoardingProvider>
                            {/* <CreateSpaceLikeAndMessagesManagerProvider
                            queryClient={queryClient}
                          >
                            <ChallengeSetLikeAndMessagesManagerProvider
                              queryClient={queryClient}
                            > */}
                            <LikesAndMessagesReportManagerProvider
                              queryClient={queryClient}
                            >
                              <CreateSpaceReportManagerProvider>
                                <ChallengeSetReportManagerProvider>
                                  {isStaging && (
                                    <div
                                      data-testid="banner"
                                      className={
                                        'w-full py-2 text-center  bg-orange-400 text-sm font-semibold '
                                      }
                                    >
                                      This is a staging environment.{' '}
                                    </div>
                                  )}
                                  {isDev && (
                                    <div
                                      data-testid="banner"
                                      className={
                                        'w-full py-2 text-white animate-bg-gradient text-sm font-semibold text-center'
                                      }
                                    >
                                      This is a development environment.{' '}
                                    </div>
                                  )}

                                  <Component {...pageProps} />
                                </ChallengeSetReportManagerProvider>
                              </CreateSpaceReportManagerProvider>
                            </LikesAndMessagesReportManagerProvider>
                            {/* </ChallengeSetLikeAndMessagesManagerProvider>
                          </CreateSpaceLikeAndMessagesManagerProvider> */}
                          </OnBoardingProvider>
                        </NotebookTemplateSetProvider>
                      </TaskModalProvider>
                    </MetaProvider>
                  </TooltipProvider>
                  <Analytics />
                </HydrationBoundary>
              </CustomI18nextProvider>
            </CSPostHogProvider>
          </QueryClientProvider>
        </SessionProvider>
      </WebViewProvider>
    </TourProvider>
  );
};

export default PageWrapper;

// this is required to comply with apple's webview policy from the game. not allowing user to register from webview.
const WebViewSetup: React.FC = () => {
  const router = useRouter();
  const { setIsWebView } = useWebView();

  useEffect(() => {
    if (router.query.webview === 'true') {
      setIsWebView(true);
      sessionStorage.setItem('isWebView', 'true');
    }
  }, [router.query]);

  return null;
};

const hasSeenOnBoarding = async ({
  hasSeenOnBoarding,
}: UserUpdateHasSeenOnboarding) => {
  const { data } = await axiosInstance.patch<ClientUser>(
    `${Routes.API.USERS}/onboarding`,
    { hasSeenOnBoarding }
  );
  return data;
};
